var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    {
      attrs: { title: "Estimate", collapsable: "", expanded: _vm.is_expanded },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-wrapper",
          attrs: { "auto-complete": "on", "label-width": "160px" },
        },
        [
          _c("el-form-item", { attrs: { label: "Estimate" } }, [
            _c(
              "div",
              { staticClass: "estimate-selector" },
              [
                _c("estimate-dropdown", {
                  attrs: {
                    project_id: _vm.project.id,
                    change_request_linked: _vm.change_request.id,
                    disabled: !_vm.is_selectable,
                  },
                  model: {
                    value: _vm.selectedEstimate,
                    callback: function ($$v) {
                      _vm.selectedEstimate = $$v
                    },
                    expression: "selectedEstimate",
                  },
                }),
                _vm.is_selectable
                  ? [
                      _vm.change_request.estimate
                        ? _c(
                            "el-button",
                            { on: { click: _vm.handleViewEstimate } },
                            [_vm._v(" View ")]
                          )
                        : [
                            _c("span", [_vm._v("or")]),
                            _c(
                              "el-button",
                              { on: { click: _vm.handleCreateEstimate } },
                              [_vm._v(" Create estimate ")]
                            ),
                          ],
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }