var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "container", on: { click: _vm.handleClick } },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "el-tag",
            {
              staticClass: "ref",
              attrs: {
                type: "info",
                "disable-transitions": true,
                size: "small",
              },
            },
            [_vm._v(" " + _vm._s(_vm.change_request.ref) + " ")]
          ),
          _c("span", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.change_request.title) + " "),
          ]),
          _vm.change_request.estimate
            ? _c(
                "el-tag",
                {
                  attrs: { size: "small", type: "info" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleViewEstimate.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-link" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("fireRef2id")(_vm.change_request.estimate)
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-tag",
            {
              attrs: {
                "disable-transitions": true,
                size: "small",
                type: _vm.tagType,
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.status2Label(_vm.change_request.status)) + " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }