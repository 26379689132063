var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.change_request
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              _c("span", { staticClass: "ref" }, [
                _vm._v(" / "),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.change_request.ref) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "el-row",
            { attrs: { gutter: 12 } },
            [
              _c(
                "el-col",
                { staticStyle: { "min-width": "220px" }, attrs: { sm: 6 } },
                [
                  _c(
                    "content-block",
                    { attrs: { title: "Status" } },
                    [
                      _c("steps", {
                        attrs: {
                          steps: _vm.steps,
                          current_step: _vm.crStatusIndex,
                          offset: 1,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        { name: "only-admin", rawName: "v-only-admin" },
                      ],
                      staticClass: "options",
                    },
                    [
                      _c("li", [
                        _vm.change_request.status === _vm.CR_STATUS.IN_PROGRESS
                          ? _c(
                              "a",
                              {
                                staticClass: "archive-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateStatus(
                                      _vm.CR_STATUS.CLOSED
                                    )
                                  },
                                },
                              },
                              [
                                _c("b", [_vm._v("Close")]),
                                _vm._v(" - Job done! "),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("li", [
                        _vm.change_request.status != _vm.CR_STATUS.ARCHIVED
                          ? _c(
                              "a",
                              {
                                staticClass: "archive-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateStatus(
                                      _vm.CR_STATUS.ARCHIVED
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Archive ")]
                            )
                          : _vm._e(),
                      ]),
                      _c("li", [
                        _vm.change_request.status > _vm.CR_STATUS.BACKLOG
                          ? _c(
                              "a",
                              {
                                staticClass: "archive-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateStatus(
                                      _vm.CR_STATUS.BACKLOG
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Bring back to Backlog ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { sm: 18 } },
                [
                  _c("ChangeRequest__scope", {
                    attrs: {
                      change_request: _vm.change_request,
                      project: _vm.project,
                    },
                  }),
                  _c("ChangeRequest__link", {
                    attrs: {
                      change_request: _vm.change_request,
                      project: _vm.project,
                    },
                  }),
                  _c("ChangeRequest__estimate", {
                    attrs: {
                      change_request: _vm.change_request,
                      project: _vm.project,
                      estimate: _vm.estimate,
                    },
                  }),
                  _c("ChangeRequest__modules", {
                    attrs: {
                      change_request: _vm.change_request,
                      project: _vm.project,
                    },
                  }),
                  _c("ChangeRequest__jira", {
                    attrs: {
                      change_request: _vm.change_request,
                      project: _vm.project,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }