var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "content-block",
        { attrs: { title: "Estimates", no_background: "" } },
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.handleClickOnNewEstimate },
                },
                [_vm._v(" New ")]
              ),
            ],
            1
          ),
          _vm.loading
            ? _c("loader")
            : !_vm.estimates.length
            ? _c("el-alert", {
                attrs: {
                  description: "No estimates have been created yet.",
                  closable: false,
                },
              })
            : [
                _vm.estimates_accepted.length
                  ? _c(
                      "content-block",
                      { attrs: { title: "Accepted" } },
                      _vm._l(_vm.estimates_accepted, function (estimate) {
                        return _c("estimate-block", {
                          key: estimate.id,
                          attrs: { estimate: estimate, project: _vm.project },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.estimates_sent.length
                  ? _c(
                      "content-block",
                      { attrs: { title: "Sent" } },
                      _vm._l(_vm.estimates_sent, function (estimate) {
                        return _c("estimate-block", {
                          key: estimate.id,
                          attrs: { estimate: estimate, project: _vm.project },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.estimates_pending.length
                  ? _c(
                      "content-block",
                      { attrs: { title: "In review" } },
                      _vm._l(_vm.estimates_pending, function (estimate) {
                        return _c("estimate-block", {
                          key: estimate.id,
                          attrs: { estimate: estimate, project: _vm.project },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.estimates_draft.length
                  ? _c(
                      "content-block",
                      { attrs: { title: "Draft" } },
                      _vm._l(_vm.estimates_draft, function (estimate) {
                        return _c("estimate-block", {
                          key: estimate.id,
                          attrs: { estimate: estimate, project: _vm.project },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.estimates_declined.length
                  ? _c(
                      "content-block",
                      { attrs: { title: "Declined" } },
                      _vm._l(_vm.estimates_declined, function (estimate) {
                        return _c("estimate-block", {
                          key: estimate.id,
                          attrs: { estimate: estimate, project: _vm.project },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }