var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-timeline-item",
    {
      attrs: {
        timestamp: _vm.timestamp,
        color: _vm.color,
        icon: _vm.icon,
        size: _vm.size,
        placement: _vm.placement,
      },
    },
    [
      _c("el-card", { staticClass: "milestone", attrs: { shadow: "never" } }, [
        _c("label", [_vm._v(_vm._s(_vm.title))]),
        _c("p", [_vm._v(_vm._s(_vm.content))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }