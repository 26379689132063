var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Scoping", collapsable: "", expanded: _vm.is_expanded } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-wrapper",
          attrs: { "auto-complete": "on", "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Assign for scoping" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "Select user",
                    "value-key": "id",
                    disabled: !_vm.can_edit,
                  },
                  model: {
                    value: _vm.change_request.assigned_to_for_scoping,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.change_request,
                        "assigned_to_for_scoping",
                        $$v
                      )
                    },
                    expression: "change_request.assigned_to_for_scoping",
                  },
                },
                [
                  _c("el-option", {
                    key: "un-assign",
                    attrs: { label: "Unassign", value: null },
                  }),
                  _c("hr"),
                  _vm._l(_vm.users, function (u) {
                    return _c("el-option", {
                      key: u.id,
                      attrs: { label: u.name, value: `users/${u.id}` },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }