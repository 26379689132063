var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "current-status" },
      [
        _c("label", { staticClass: "title" }, [_vm._v(" Next Milestone ")]),
        _c(
          "el-card",
          { staticClass: "status-card", attrs: { shadow: "never" } },
          [
            _c("h4", [_vm._v(_vm._s(_vm.current_milestone.content))]),
            _c("div", { staticClass: "time" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("timeAgo")(_vm.current_milestone.start.toDate())
                  ) +
                  " "
              ),
              _c("span", { staticClass: "light" }, [_vm._v("/")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateformat")(
                      _vm.current_milestone.start.toDate(),
                      "DD/MM/YY"
                    )
                  ) +
                  " "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }