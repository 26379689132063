var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step" }, [
    _c("span", [
      _c("div", { staticClass: "icon" }, [
        _c(
          "div",
          { staticClass: "circle", class: _vm.status },
          [_c("svgicon", { attrs: { name: _vm.icon, color: _vm.color } })],
          1
        ),
      ]),
      _c("div", { staticClass: "name", attrs: { title: "Build" } }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ]),
    _vm.substeps
      ? _c(
          "div",
          {
            staticClass: "content",
            style: { height: `${_vm.substepsHeight}px` },
          },
          _vm._l(_vm.substeps, function (substep) {
            return _c(
              "div",
              {
                key: substep.key,
                staticClass: "substep",
                class: { clickable: substep.action },
                on: {
                  click: function ($event) {
                    return _vm.handleSubstepAction(substep)
                  },
                },
              },
              [
                _c("svgicon", {
                  attrs: {
                    name: substep.completed
                      ? "steps-success-icon"
                      : "steps-todo-icon",
                    color: _vm.substepColor(substep),
                  },
                }),
                _c("span", [_vm._v(_vm._s(substep.title))]),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }