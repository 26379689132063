var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "timeline-container" },
      [
        _c(
          "el-timeline",
          _vm._l(_vm.events, function (event) {
            return _c("timeline-item", {
              key: event.id,
              attrs: { event: event, event_types: _vm.event_types },
            })
          }),
          1
        ),
      ],
      1
    ),
    !_vm.eventsLoaded
      ? _c("div", { staticClass: "load-more", on: { click: _vm.loadEvents } }, [
          _c("span", [_vm._v("Load more events")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }