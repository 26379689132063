var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    !_vm.project.current_status || _vm.updatingStatus
      ? _c(
          "div",
          {
            directives: [
              {
                name: "has-project-permission",
                rawName: "v-has-project-permission",
                value: "project.status.update",
                expression: "'project.status.update'",
              },
            ],
            staticClass: "current-status",
          },
          [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("h3", [_vm._v("Project Status")]),
                _vm.project.current_status
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.cancelUpdate },
                      },
                      [_vm._v(" Cancel ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-card",
              {
                style: {
                  borderTopColor: _vm.selectedStatus
                    ? _vm.selectedStatus.color
                    : "transparent",
                },
                attrs: { shadow: "never" },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Status" },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function ($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus",
                    },
                  },
                  _vm._l(_vm.statuses, function (s) {
                    return _c(
                      "el-option",
                      {
                        key: s.value,
                        staticClass: "status-option",
                        attrs: {
                          label: s.label,
                          value: s,
                          "value-key": "value",
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "dot",
                          style: { backgroundColor: s.color },
                        }),
                        _c("span", [_vm._v(_vm._s(s.label))]),
                      ]
                    )
                  }),
                  1
                ),
                _c("el-input", {
                  attrs: {
                    resize: "none",
                    type: "textarea",
                    placeholder: "Notes",
                    maxlength: "140",
                    "show-word-limit": "",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.createStatus.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.content,
                    callback: function ($$v) {
                      _vm.content = $$v
                    },
                    expression: "content",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm.current_status
      ? _c(
          "div",
          { staticClass: "current-status" },
          [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("h3", { style: { color: _vm.current_status.color } }, [
                  _vm._v(" " + _vm._s(_vm.current_status.label) + " "),
                ]),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "has-project-permission",
                        rawName: "v-has-project-permission",
                        value: "project.status.update",
                        expression: "'project.status.update'",
                      },
                    ],
                    attrs: { size: "mini" },
                    on: { click: _vm.updateStatus },
                  },
                  [_vm._v(" Set Status ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "time-spent" }, [
              _c("label", [_vm._v("Time spent this week: ")]),
              !_vm.loadingSessions
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("milli2duration")(_vm.time_spent_this_week)
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "el-card",
              {
                staticClass: "status-card",
                style: { borderTopColor: _vm.current_status.color },
                attrs: { shadow: "never" },
              },
              [
                _c("h4", [_vm._v(_vm._s(_vm.current_event.content))]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("timeAgo")(_vm.current_event.created_at.toDate())
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }