var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        placeholder: "Select an estimate",
        disabled: _vm.disabled,
        clearable: "",
      },
      model: {
        value: _vm.selectedEstimate,
        callback: function ($$v) {
          _vm.selectedEstimate = $$v
        },
        expression: "selectedEstimate",
      },
    },
    _vm._l(_vm.filteredEstimates, function (estimate) {
      return _c(
        "el-option",
        {
          key: estimate.id,
          attrs: { value: estimate.id, label: estimate.title },
        },
        [
          _c("div", { staticClass: "estimate-row" }, [
            _c("span", { staticClass: "estimate-name" }, [
              _vm._v(" " + _vm._s(estimate.title) + " "),
            ]),
            _c("span", { staticClass: "estimate-ref" }, [
              _vm._v(" " + _vm._s(estimate.ref) + " "),
            ]),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }