var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", on: { click: _vm.handleClick } },
    [
      _c("span", { staticClass: "type" }, [
        _vm._v(_vm._s(_vm.module.type.value)),
      ]),
      _c("span", { staticClass: "subtype" }, [
        _vm._v(_vm._s(_vm.module.subtype.value)),
      ]),
      _vm.environments.length
        ? _c(
            "div",
            { staticClass: "environments" },
            _vm._l(_vm.environments, function (env) {
              return _c("module-environment-status", {
                key: env.name,
                attrs: {
                  branch: env.branch,
                  environment: env.name,
                  repository: _vm.module.repository,
                  workspace: _vm.module.workspace,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }