var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "el-dropdown",
        {
          attrs: { trigger: "click", placement: "bottom-end" },
          on: { command: _vm.assignRole },
        },
        [
          _c(
            "div",
            { staticClass: "user" },
            [
              _c("avatar", { attrs: { user: _vm.user, hide_tooltip: "" } }),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.user.name)),
                ]),
                _c("div", { staticClass: "role" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.assigned_role
                          ? _vm.assigned_role.label
                          : "+ Add role"
                      ) +
                      " "
                  ),
                ]),
              ]),
              !_vm.assigned_role
                ? _c(
                    "div",
                    {
                      directives: [
                        { name: "only-admin", rawName: "v-only-admin" },
                      ],
                      staticClass: "down-icon",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { focusable: "false", viewBox: "0 0 24 24" },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _vm._l(_vm.roles, function (option) {
                return _c(
                  "el-dropdown-item",
                  { key: option.id, attrs: { command: option.id } },
                  [_vm._v(" " + _vm._s(option.label) + " ")]
                )
              }),
              _c(
                "el-dropdown-item",
                { staticClass: "destructive", attrs: { divided: "" } },
                [_c("span", { attrs: { command: "x" } }, [_vm._v("Remove")])]
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }