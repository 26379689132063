var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Scope" } },
    [
      _c(
        "template",
        { slot: "right" },
        [
          _vm.is_editable
            ? [
                !_vm.editing
                  ? _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.editScope } },
                      [_vm._v(" Edit ")]
                    )
                  : _vm._e(),
                _vm.editing
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.stopEditingScope },
                      },
                      [_vm._v(" Cancel ")]
                    )
                  : _vm._e(),
                _vm.editing
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.saveScope },
                      },
                      [_vm._v(" Save ")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          !_vm.editing
            ? [
                _vm.is_scope_assigned_to_me
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.confirmScope },
                      },
                      [_vm._v(" Confirm scope ")]
                    )
                  : _vm._e(),
                _vm.is_waiting_for_approval
                  ? [
                      _c(
                        "el-button",
                        {
                          key: _vm.is_sent_for_approval
                            ? "send-button"
                            : "resend-button",
                          attrs: {
                            size: "mini",
                            type: _vm.is_sent_for_approval
                              ? "secondary"
                              : "primary",
                          },
                          on: { click: _vm.sendToClient },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.is_sent_for_approval ? "Re-send" : "Send"
                              ) +
                              " to client "
                          ),
                        ]
                      ),
                      _vm.is_sent_for_approval
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.confirmPreApproval },
                            },
                            [_vm._v(" Confirm pre-approval ")]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.is_estimate_assigned
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.finishQuoting },
                      },
                      [_vm._v(" Finish quoting ")]
                    )
                  : _vm._e(),
                _vm.is_waiting_for_contract
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.confirmContract },
                      },
                      [_vm._v(" Confirm contract ")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.editing
        ? _c("h2", [_vm._v(_vm._s(_vm.change_request.title))])
        : _c("el-input", {
            staticClass: "title-input",
            model: {
              value: _vm.change_request.title,
              callback: function ($$v) {
                _vm.$set(_vm.change_request, "title", $$v)
              },
              expression: "change_request.title",
            },
          }),
      _c("text-editor", {
        ref: "content_editor",
        attrs: {
          content: _vm.change_request.content,
          placeholder: "Add scope info here…",
          editable: _vm.editing,
        },
        on: {
          "update:content": function ($event) {
            return _vm.$set(_vm.change_request, "content", $event)
          },
        },
      }),
      _c("text-editor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !(_vm.is_closed && !_vm.change_request.note),
            expression: "!(is_closed && !change_request.note)",
          },
        ],
        ref: "note_editor",
        staticStyle: { "margin-top": "10px" },
        attrs: {
          content: _vm.change_request.note,
          placeholder: "Add notes here…",
          editable: !_vm.is_closed,
        },
        on: {
          "update:content": [
            function ($event) {
              return _vm.$set(_vm.change_request, "note", $event)
            },
            _vm.saveNote,
          ],
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }