var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("aside", { staticClass: "menu" }, [
        _c(
          "ul",
          _vm._l(_vm.typeCollection, function (type) {
            return _c(
              "li",
              {
                key: type.name,
                class: {
                  active: _vm.selectedType ? _vm.selectedType === type : false,
                },
                on: { click: () => (_vm.selectedType = type) },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm._f("capitalize")(type.name)))]),
                _c("svgicon", {
                  staticClass: "triangle",
                  attrs: { name: "triangle" },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "content-block",
        {
          staticClass: "document-list",
          attrs: { title: "Documents", no_background: "" },
        },
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-dropdown",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { trigger: "click", placement: "bottom" },
                  on: { command: _vm.handleNewDocument },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "dropdown",
                      fn: function () {
                        return [
                          _c(
                            "el-dropdown-menu",
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "summary" } },
                                [_c("span", [_vm._v("Summary")])]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "estimate" } },
                                [_c("span", [_vm._v("Estimate")])]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "link" } },
                                [_c("span", [_vm._v("From link")])]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-button", { attrs: { size: "mini" } }, [
                    _vm._v(" New "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loading
            ? _c("loader")
            : !_vm.selectedType || !_vm.selectedType.documents.length
            ? _c("el-alert", {
                attrs: {
                  description: "No documents have been created yet.",
                  closable: false,
                },
              })
            : _vm._l(_vm.selectedType.documents, function (doc) {
                return _c(
                  "div",
                  {
                    key: doc.id,
                    staticClass: "document-row",
                    class: {
                      invalid: !doc.isExternal && !doc.valid,
                      editable: !doc.isExternal,
                    },
                    attrs: {
                      type: doc.isExternal || doc.valid ? null : "warning",
                    },
                    on: { click: () => _vm.editDocument(doc) },
                  },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(doc.name) + " "),
                    ]),
                    doc.isExternal
                      ? _c("el-button", {
                          attrs: { icon: "el-icon-link" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return (() => _vm.handleExternalLink(doc)).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        })
                      : _c("el-button", {
                          attrs: {
                            icon: "el-icon-download",
                            disabled: !doc.valid,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return (() =>
                                doc.valid
                                  ? _vm.generateDocumentPDF(doc)
                                  : 0).apply(null, arguments)
                            },
                          },
                        }),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "only-super-admin",
                            rawName: "v-only-super-admin",
                          },
                        ],
                        staticClass: "document-delete-confirm",
                        attrs: { title: "Delete this document?" },
                        on: {
                          confirm: () => _vm.deleteDocument(doc),
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "reference",
                            type: "danger",
                            plain: "",
                            icon: "el-icon-delete",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }