var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "status", class: _vm.type }, [
    _c("label", [_vm._v(_vm._s(_vm.label))]),
    _c("span", { staticClass: "row" }, [
      _c("span", [_vm._v(_vm._s(_vm.sign))]),
      _vm._v(" " + _vm._s(_vm._f("milli2duration")(_vm.time)) + " "),
      _c(
        "span",
        { staticClass: "small" },
        [
          _vm.show_calculations
            ? [
                _vm._v(" ( "),
                _vm._l(_vm.filtered_calculations, function (calculation, idx) {
                  return _c(
                    "span",
                    {
                      key: idx,
                      class: {
                        offset: calculation.offset,
                        success: calculation.offset && calculation.value > 0,
                        danger: calculation.offset && calculation.value < 0,
                      },
                      style: { color: calculation.color },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: calculation.label,
                            placement: "top",
                          },
                        },
                        [
                          _c("span", [
                            calculation.value < 0
                              ? _c("span", [_vm._v(" - ")])
                              : idx > 0
                              ? _c("span", [_vm._v(" + ")])
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("replace")(
                                    _vm._f("milli2duration")(calculation.value),
                                    "-",
                                    ""
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" ) "),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }