var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stage-notes" },
    [
      !_vm.editing
        ? _c(
            "p",
            {
              staticClass: "notes",
              class: { "can-edit": _vm.user_is_admin },
              on: { click: _vm.toggleEdit },
            },
            [_vm._v(" " + _vm._s(_vm.value || "Add notes") + " ")]
          )
        : _vm._e(),
      _vm.editing
        ? _c("el-input", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.toggleEdit,
                expression: "toggleEdit",
              },
            ],
            attrs: { size: "small", placeholder: "Insert note" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.toggleEdit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.notes,
              callback: function ($$v) {
                _vm.notes = $$v
              },
              expression: "notes",
            },
          })
        : _vm._e(),
      _vm.editing
        ? _c("span", { staticClass: "info" }, [_vm._v("( enter to close )")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }