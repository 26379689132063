var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modules.length
    ? _c(
        "content-block",
        {
          attrs: {
            title: "Affected modules",
            collapsable: _vm.is_expanded,
            expanded: "",
          },
        },
        [
          _c(
            "ul",
            { staticClass: "modules" },
            _vm._l(_vm.modules, function (module) {
              return _c("module-block", {
                key: module.id,
                class: {
                  selected: _vm.isSelected(module),
                  selectable: _vm.is_expanded,
                },
                attrs: { module: module },
                nativeOn: {
                  click: function ($event) {
                    return _vm.selected(module)
                  },
                },
              })
            }),
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }