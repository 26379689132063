var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status" },
    [
      _c("label", [_vm._v(_vm._s(_vm.environment))]),
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "spinner",
            attrs: {
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(0, 0, 0, 0)",
            },
          })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.time,
                "open-delay": 300,
                placement: "right",
              },
            },
            [
              _c(
                "el-tag",
                {
                  attrs: {
                    size: "medium",
                    "disable-transitions": true,
                    type: _vm.status_type,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.build_name) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }