var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-timeline-item",
    {
      attrs: {
        timestamp: _vm.timestamp,
        color: _vm.color,
        icon: _vm.icon,
        size: _vm.size,
        placement: _vm.placement,
      },
    },
    [
      _c("div", { staticClass: "status" }, [
        _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
        _c(
          "div",
          { staticClass: "info" },
          [
            _vm.created_by
              ? _c("avatar", {
                  attrs: { user: _vm.created_by, size: "normal" },
                })
              : _vm._e(),
            _c("div", { staticClass: "time" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("timeAgo")(_vm.event.created_at.toDate())) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }