var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Modules", no_background: "" } },
    [
      !_vm.modules.length
        ? _c("el-alert", {
            attrs: {
              description: "No modules created for this project.",
              closable: false,
            },
          })
        : _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.modules, function (module) {
              return _c("project-module", {
                key: module.id,
                attrs: { module: module },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }