var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "content-block",
        [
          _c("project-timeline", {
            ref: "timeline_ref",
            attrs: {
              project_ids: [_vm.$route.params.project_id],
              milestones: _vm.milestones,
            },
          }),
        ],
        1
      ),
      _c("project-milestone", {
        attrs: {
          project_id: _vm.$route.params.project_id,
          milestones: _vm.milestones,
          types: _vm.event_types,
        },
        on: { "click:milestone": _vm.milestoneClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }