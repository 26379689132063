var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-container" },
    [
      !_vm.editing
        ? _c(
            "el-tag",
            {
              staticClass: "env",
              attrs: {
                "disable-transitions": true,
                size: "small",
                type: _vm.env_type,
              },
            },
            [_vm._v(" " + _vm._s(_vm.item.env) + " ")]
          )
        : _c(
            "el-select",
            {
              staticClass: "env",
              attrs: { placeholder: "Select", size: "mini" },
              model: {
                value: _vm.item.env,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "env", $$v)
                },
                expression: "item.env",
              },
            },
            _vm._l(_vm.options, function (i) {
              return _c("el-option", {
                key: i.value,
                attrs: { label: i.label, value: i.value },
              })
            }),
            1
          ),
      _c("label", [_vm._v(_vm._s(_vm.item.title) + ":")]),
      !_vm.editing
        ? _c(
            "a",
            { attrs: { href: _vm.item.value, target: "_blank" } },
            [
              _c(
                "el-tag",
                {
                  staticClass: "value",
                  attrs: {
                    type: "info",
                    "disable-transitions": true,
                    size: "small",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.item.value) + " ")]
              ),
            ],
            1
          )
        : _c("el-input", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.toggleEdit,
                expression: "toggleEdit",
              },
            ],
            attrs: { size: "mini", placeholder: "Insert path" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.toggleEdit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.path,
              callback: function ($$v) {
                _vm.path = $$v
              },
              expression: "path",
            },
          }),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.item.value,
              expression: "item.value",
              arg: "copy",
            },
          ],
          attrs: { size: "mini" },
        },
        [_vm._v("path")]
      ),
      _vm.show_editing
        ? _c(
            "el-button",
            {
              directives: [
                { name: "only-super-admin", rawName: "v-only-super-admin" },
              ],
              attrs: { size: "mini", type: "warning", plain: "" },
              on: { click: _vm.toggleEdit },
            },
            [_vm._v(" edit ")]
          )
        : _vm._e(),
      _vm.show_editing
        ? _c(
            "el-button",
            {
              directives: [
                { name: "only-super-admin", rawName: "v-only-super-admin" },
              ],
              attrs: { size: "mini", type: "danger", plain: "" },
              on: { click: _vm.deleteItem },
            },
            [_vm._v(" remove ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }