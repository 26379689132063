var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.today_calendar.length > 0
      ? _c(
          "div",
          { staticClass: "current-status" },
          [
            _c("label", { staticClass: "title" }, [_vm._v(" Today ")]),
            _vm._l(_vm.today_calendar, function (cal) {
              return _c("calendar-item-card", {
                key: cal.id,
                staticClass: "status-card",
                attrs: { shadow: "never", event: cal },
              })
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.tomorrow_calendar.length > 0
      ? _c(
          "div",
          { staticClass: "current-status" },
          [
            _c("label", { staticClass: "title" }, [_vm._v(" Tomorrow ")]),
            _vm._l(_vm.tomorrow_calendar, function (cal) {
              return _c("calendar-item-card", {
                key: cal.id,
                staticClass: "status-card",
                attrs: { shadow: "never", event: cal },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }