var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.module
    ? _c(
        "li",
        {
          staticClass: "container",
          on: {
            click: function ($event) {
              return _vm.$emit("click", _vm.module)
            },
          },
        },
        [
          _c("span", { staticClass: "type" }, [
            _vm._v(_vm._s(_vm.module.type.value)),
          ]),
          _c("span", { staticClass: "subtype" }, [
            _vm._v(_vm._s(_vm.module.subtype.value)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }