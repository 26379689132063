var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("highlighted-components-block"),
      _vm._l(_vm.stages, function (stage) {
        return _c("stage-block", {
          key: stage.id,
          attrs: { stage_ref: `stages/${stage.id}` },
        })
      }),
      _c(
        "div",
        {
          directives: [{ name: "only-admin", rawName: "v-only-admin" }],
          staticClass: "stage new",
          on: { click: _vm.handleClickOnNewStage },
        },
        [
          _c("svgicon", {
            staticClass: "icon",
            attrs: { name: "plus-square" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }