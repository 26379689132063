var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "content-block",
        { attrs: { title: "Change requests", no_background: "" } },
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.handleClickOnNewCR },
                },
                [_vm._v(" New ")]
              ),
            ],
            1
          ),
          _vm.active_change_requests.length
            ? _c(
                "ul",
                { staticClass: "list-active" },
                _vm._l(_vm.active_change_requests, function (change_request) {
                  return _c("change-request-block", {
                    key: change_request.id,
                    attrs: {
                      change_request: change_request,
                      project: _vm.project,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.future_change_requests.length
            ? _c(
                "ul",
                { staticClass: "list-future" },
                _vm._l(_vm.future_change_requests, function (change_request) {
                  return _c("change-request-block", {
                    key: change_request.id,
                    attrs: {
                      change_request: change_request,
                      project: _vm.project,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.closed_change_requests.length
            ? _c(
                "ul",
                { staticClass: "list-closed" },
                _vm._l(_vm.closed_change_requests, function (change_request) {
                  return _c("change-request-block", {
                    key: change_request.id,
                    attrs: {
                      change_request: change_request,
                      project: _vm.project,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        2
      ),
      !_vm.change_requests_options.show || _vm.change_requests_options.loading
        ? _c(
            "div",
            {
              staticClass: "load-more",
              on: { click: _vm.handleClickLoadClosed },
            },
            [
              _vm.change_requests_options.loading
                ? _c("loader")
                : _c("span", [_vm._v("Show closed CRs")]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }