var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list" },
    _vm._l(_vm.notes, function (note) {
      return _c("note-block", {
        key: note.id,
        attrs: { note: note, search: _vm.search },
        nativeOn: {
          click: function ($event) {
            return _vm.handleClick(note)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }