var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      directives: [
        {
          name: "only-super-admin",
          rawName: "v-only-super-admin",
          value: _vm.note.is_private,
          expression: "note.is_private",
        },
      ],
      staticClass: "item",
      class: { locked: _vm.note.is_private },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", [_c("span", [_vm._v(_vm._s(_vm.note.title))])]),
        _c("div", { staticClass: "info" }, [
          _vm.note.updated_at
            ? _c("div", { staticClass: "row updated_at" }, [
                _c("span", [
                  _vm._v(
                    " Last update " +
                      _vm._s(
                        _vm._f("timeAgo")(
                          _vm._f("timestamp2moment")(
                            _vm.note.updated_at.seconds
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row created_at" },
            [
              _c("span", [
                _vm._v(
                  " Created " +
                    _vm._s(
                      _vm._f("timeAgo")(
                        _vm._f("timestamp2moment")(_vm.note.created_at.seconds)
                      )
                    ) +
                    " by "
                ),
              ]),
              _c("avatar", { attrs: { user: _vm.author } }),
            ],
            1
          ),
        ]),
      ]),
      _vm.search && _vm.search.length > 1
        ? _c(
            "ul",
            { staticClass: "search" },
            _vm._l(_vm.results, function (result) {
              return _c("li", { key: result.id, staticClass: "result" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(result.before) + " "),
                  _c("span", [_vm._v(_vm._s(result.match))]),
                  _vm._v(" " + _vm._s(result.after) + " "),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }