var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overview-container" }, [
    _c(
      "div",
      { staticClass: "main" },
      [_c("todo-list"), _c("project-team")],
      1
    ),
    _c(
      "aside",
      [
        _c("project-status"),
        _c("project-calendar-upcoming", {
          attrs: { calendar: _vm.filtered_calendar },
        }),
        _vm.current_milestone
          ? _c("project-milestone", {
              attrs: { current_milestone: _vm.current_milestone },
            })
          : _vm._e(),
        _c("project-events", {
          attrs: {
            current_milestone: _vm.current_milestone,
            calendar: _vm.filtered_calendar,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }