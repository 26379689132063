var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "stage-container" }, [
    _c(
      "div",
      { staticClass: "head" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { lg: 16, sm: 24 } },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "title",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickOnStage("_overview")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.stage.name ? _vm.stage.name : "Stage") +
                            " "
                        ),
                        _c("strong", [_vm._v("#" + _vm._s(_vm.stage.number))]),
                      ]
                    ),
                    [
                      _c("el-tooltip", { attrs: { placement: "bottom" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(" spent / "),
                            _vm.total_reportable > 0
                              ? _c("span", [_vm._v("reportable /")])
                              : _vm._e(),
                            _vm._v(" estimate "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "only-super-admin",
                                    rawName: "v-only-super-admin",
                                  },
                                ],
                              },
                              [_vm._v("/ invoiced")]
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "status time",
                            on: {
                              click: function ($event) {
                                _vm.show_reportable = !_vm.show_reportable
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("milli2duration")(_vm.total_spent)
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" / "),
                            _vm.total_reportable > 0
                              ? [
                                  _c("span", { staticClass: "reportable" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("milli2duration")(
                                            _vm.total_reportable
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._v(" / "),
                                ]
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("milli2duration")(_vm.total_estimate)
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "only-super-admin",
                                    rawName: "v-only-super-admin",
                                  },
                                ],
                                staticClass: "hidden",
                              },
                              [
                                _vm._v(" / "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("hours2duration")(
                                          _vm.invoiced_hours
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                      !_vm.collapsed
                        ? _c(
                            "div",
                            { staticClass: "users desktop-only" },
                            [
                              _c("face-pile", {
                                attrs: {
                                  users: [..._vm.users],
                                  size: "mini",
                                  time_data: _vm.tracked_users_time,
                                  hide_tooltip: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                  ],
                  2
                ),
                _c("stage-notes", {
                  attrs: { value: _vm.stage.notes || "" },
                  on: { change: (value) => _vm.update("notes", value) },
                }),
              ],
              1
            ),
            _c(
              "el-col",
              {
                directives: [{ name: "only-admin", rawName: "v-only-admin" }],
                staticClass: "options-col",
                attrs: { span: 8 },
              },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      trigger: "click",
                      "popper-class": "options-popover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "options-trigger",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [_c("svgicon", { attrs: { name: "settings" } })],
                      1
                    ),
                    _c("ul", [
                      _c(
                        "li",
                        { on: { click: _vm.handleClickOnNewComponent } },
                        [_vm._v(" Add component ")]
                      ),
                      _c("hr"),
                      _vm.is_stage_completed
                        ? _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStage(
                                    "status",
                                    _vm.STAGE_STATUS.ACTIVE
                                  )
                                },
                              },
                            },
                            [_vm._v(" Mask as active ")]
                          )
                        : _vm._e(),
                      _vm.is_stage_active
                        ? _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStage(
                                    "status",
                                    _vm.STAGE_STATUS.COMPLETED
                                  )
                                },
                              },
                            },
                            [_vm._v(" Mark as completed ")]
                          )
                        : _vm._e(),
                      _c("hr"),
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleClickOnStage("_settings")
                            },
                          },
                        },
                        [_vm._v(" Settings ")]
                      ),
                      _vm.is_stage_completed
                        ? _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.collapsed = !_vm.collapsed
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.collapsed ? "Expand" : "Collapse"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.collapsed
      ? _c(
          "div",
          { staticClass: "components-container" },
          [
            !_vm.components.length
              ? _c("el-alert", {
                  attrs: { description: "No components yet.", closable: false },
                })
              : [
                  _vm._l(_vm.recurring_components, function (component) {
                    return _c("recurring-component-block", {
                      key: `recurring_${component.id}`,
                      attrs: { component: component, simple_ui: "" },
                    })
                  }),
                  _vm._l(_vm.non_recurring_components, function (component) {
                    return _c("component-block", {
                      key: `${component.id}`,
                      attrs: { component: component },
                    })
                  }),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }