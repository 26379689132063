var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.notes.loading
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-input", {
                attrs: {
                  name: "search",
                  type: "text",
                  "auto-complete": "off",
                  placeholder: "Search in notes",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm.temporary_note
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleClickOnContinueEditing },
                    },
                    [_vm._v(" Continue editing ")]
                  )
                : _c("el-button", { on: { click: _vm.handleClickOnNew } }, [
                    _vm._v(" New "),
                  ]),
            ],
            1
          ),
          _c("hr"),
          _c("list", {
            attrs: { notes: _vm.sorted_notes, search: _vm.search },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }