var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "milestone new",
        on: { click: _vm.handleClickOnNewMilestone },
      },
      [_c("svgicon", { staticClass: "icon", attrs: { name: "plus-square" } })],
      1
    ),
    _c(
      "ul",
      { staticClass: "milestones" },
      _vm._l(_vm.milestones, function (milestone) {
        return _c(
          "li",
          {
            key: milestone.id,
            staticClass: "milestone",
            on: { click: () => _vm.click(milestone) },
          },
          [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(milestone.content)),
            ]),
            _c("div", { staticClass: "date" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateformat")(milestone.start.toDate(), "DD/MM/YYYY")
                  ) +
                  " "
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }