var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "head" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { lg: 16, sm: 24 } }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "div",
                  {
                    staticClass: "col",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("click")
                      },
                    },
                  },
                  [
                    _c("label", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.component.name) + " "),
                    ]),
                    _vm.show_controls
                      ? _c("span", { staticClass: "date_range" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateformat")(_vm.start, "DD/MM/YY")
                              ) +
                              " - " +
                              _vm._s(
                                _vm._f("dateformat")(_vm.end, "DD/MM/YY")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._t("times"),
              ],
              2
            ),
          ]),
          _vm.show_controls
            ? _c(
                "el-col",
                { staticClass: "options-col", attrs: { xs: 24, lg: 8 } },
                [
                  _c(
                    "div",
                    { staticClass: "top-right" },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "only-super-admin",
                              rawName: "v-only-super-admin",
                            },
                          ],
                          staticClass: "highlight-button",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.updateComponent(
                                "highlight",
                                _vm.component.highlight ? null : true
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-star-off" })]
                      ),
                      _vm.current_label !== _vm.selected_label &&
                      _vm.next_enabled
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                size: "mini",
                                disabled: !_vm.current_enabled,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => _vm.$emit("reset")).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" Latest ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button-group",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                disabled: !_vm.prev_enabled,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => _vm.$emit("prev-block")).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("svgicon", {
                                    staticClass: "triangle",
                                    class: { disabled: !_vm.prev_enabled },
                                    staticStyle: {
                                      transform: "rotate(180deg)",
                                      "margin-right": "5px",
                                    },
                                    attrs: { width: "5", name: "triangle" },
                                  }),
                                  _vm._v(" " + _vm._s(_vm.prev_label) + " "),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "pointer-events": "none",
                                width: "120px",
                              },
                              attrs: { size: "mini" },
                            },
                            [_c("b", [_vm._v(_vm._s(_vm.selected_label))])]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                disabled: !_vm.next_enabled,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => _vm.$emit("next-block")).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _vm._v(" " + _vm._s(_vm.next_label) + " "),
                                  _c("svgicon", {
                                    staticClass: "triangle",
                                    class: { disabled: !_vm.next_enabled },
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { width: "5", name: "triangle" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }