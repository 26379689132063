var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Jira" } },
    [
      _c(
        "template",
        { slot: "right" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  _vm.adding = !_vm.adding
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.adding ? "Cancel" : "Add ticket") + " ")]
          ),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "tickets" },
        [
          _vm._l(_vm.change_request.tickets, function (ticket, idx) {
            return _c(
              "li",
              {
                key: "ticket_" + idx,
                on: {
                  click: function ($event) {
                    return _vm.openTicket(ticket)
                  },
                },
              },
              [
                _c("label", [
                  _c("span", [_vm._v(_vm._s(ticket.issue_id))]),
                  _vm._v(" - " + _vm._s(ticket.url) + " "),
                ]),
              ]
            )
          }),
          _vm.adding
            ? _c(
                "li",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "Ticket URL" },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.saveTicket },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }