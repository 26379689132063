var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.has_links
    ? _c(
        "div",
        { staticClass: "ref-links" },
        _vm._l(_vm.links, function (link, index) {
          return _c("ref-link-button", {
            key: `${link.key}_${index}`,
            attrs: { link_key: link.key, link_value: link.value },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }