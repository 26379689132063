var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("content-block", { attrs: { title: "Team", no_background: "" } }, [
    _c(
      "div",
      {
        staticStyle: { float: "right" },
        attrs: { slot: "right" },
        slot: "right",
      },
      [
        _vm.available_users.length > 0
          ? _c(
              "el-dropdown",
              {
                directives: [{ name: "only-admin", rawName: "v-only-admin" }],
                attrs: { trigger: "click", placement: "bottom" },
                on: { command: _vm.addUser },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("click_new")
                      },
                    },
                  },
                  [_vm._v(" Add ")]
                ),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.available_users, function (user) {
                    return _c(
                      "el-dropdown-item",
                      { key: user.id, attrs: { command: user.id } },
                      [_vm._v(" " + _vm._s(user.name) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "team" },
      _vm._l(_vm.project.team, function (user) {
        return _c("project-team-user", {
          key: user.user,
          staticClass: "team-user",
          attrs: { team_user: user },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }