var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.highlighted_recurring_components.length +
    _vm.highlighted_non_recurring_components.length
    ? _c(
        "section",
        { staticClass: "highlighted-components-container" },
        [
          _vm._l(_vm.highlighted_recurring_components, function (component) {
            return _c("recurring-component-block", {
              key: `recurring_${component.id}`,
              attrs: { component: component },
            })
          }),
          _vm._l(_vm.highlighted_non_recurring_components, function (
            component
          ) {
            return _c("component-block", {
              key: `${component.id}`,
              attrs: { component: component },
            })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }