var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "steps" },
    [
      _vm._l(_vm.steps, function (step, idx) {
        return _c("steps-item", {
          key: step.key,
          attrs: {
            title: step.title,
            type: step.type,
            status: _vm.statusForStepAtIndex(idx),
            substeps: step.substeps,
          },
        })
      }),
      _vm.is_archived
        ? _c("steps-item", {
            attrs: { title: "Archived", status: "completed" },
          })
        : _vm._e(),
      _c(
        "svg",
        {
          staticClass: "indicator",
          attrs: {
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            width: "28px",
            height: `${_vm.line_height}px`,
            viewBox: `0 0 28 ${_vm.line_height}`,
          },
        },
        [
          _c("g", [
            _c("path", {
              staticClass: "line",
              attrs: { d: `M 16 25 v ${_vm.line_height}` },
            }),
            _c("path", {
              staticClass: "completion_line",
              class: {
                grey: _vm.is_archived,
              },
              attrs: {
                d: `M 16 25 v ${_vm.completed_height}`,
                "data-pipe": "true",
              },
            }),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }