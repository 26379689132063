var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tag-wrapper" },
    [
      _c(
        "el-tag",
        {
          staticClass: "tag",
          attrs: {
            type: _vm.type,
            "disable-transitions": true,
            size: _vm.size,
          },
        },
        [_vm._t("default")],
        2
      ),
      _c("div", {
        staticClass: "progress",
        class: _vm.direction,
        style: {
          backgroundColor: _vm.color,
          width: _vm.progressAmount,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }